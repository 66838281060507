<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="new_mortgage" service="refMortgage" backlink="/refinance-mortgage/bank-offers" />
      <div class="content">
        <div class="content__container">
          <p class="info info_1">{{ "s5p20_text_1" | localize }}</p>
          <p class="info info_2">{{ "s5p20_text_2" | localize }}</p>
          <p class="info info_7">{{ "s5p20_text_3" | localize }}</p>

          <div class="money">
            <div class="money__item">
              <div class="money__asset">
                <div class="money__asset_progress"></div>
                <div class="money__asset_container">
                  <div class="money__asset_caption">
                     {{ "mortgage_sum" | localize }}
                  </div>
                  <div class="money__asset_text">
                    {{ Number(mortgage_sum, 10).toLocaleString() }} <span class="icon-₪"></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="money__item">
              <div class="money__capital">
                <div class="money__capital_caption">
                  {{ "bank_payment" | localize }}
                </div>
                <div class="money__capital_text">
                  {{ bank_data.payment.toLocaleString() }} <span class="icon-₪"></span>
                </div>
              </div>
              <div class="money__capital">
                <div class="money__capital_caption">
                  {{ "return_sum" | localize }}
                </div>
                <div class="money__capital_text">
                  {{ bank_data.return.toLocaleString() }} <span class="icon-₪"></span>
                </div>
              </div>
            </div>
            <div class="money__item">
              <div class="money__dot"></div>
              <div class="money__dot money__dot_solid"></div>
            </div>
          </div>

          <p class="info info_4">{{ "s5p20_text_4" | localize }}</p>

          <div class="program__container" v-if="bank_data.programs">
            <div class="program" v-for="(program, k) in bank_data.programs" :key="k">
              <div class="program__caption">{{ program.name }}</div>
              <div class="program__items">
                <div class="program__item">
                  <div class="program__item_caption">
                    {{ "sum" | localize }}
                  </div>
                  <div class="program__item_text">
                    {{ program.amount.toLocaleString() }} <span class="icon-₪"></span>
                  </div>
                </div>
                <div class="program__item">
                  <div class="program__item_caption">
                    {{ "years" | localize }}
                  </div>
                  <div class="program__item_text">
                    {{ Math.round(program.months / 12) }}
                  </div>
                </div>
                <div class="program__item">
                  <div class="program__item_caption">
                    {{ "percent" | localize }}
                  </div>
                  <div class="program__item_text">
                    {{ program.percent.toLocaleString() }} %
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="changes || old_payment">
            <p class="info info_5">{{ "s5p20_text_5" | localize }}</p>
            <p class="info info_6">{{ "s5p20_text_6" | localize }}</p>

            <div class="people" v-if="percent">
              <div class="people__percent">{{ percent.toLocaleString($store.state.lang) }} %</div>
              <p class="people__text">{{ "s5p20_text_7" | localize }}</p>
            </div>

            <div class="changes form__group">
              <div class="changes__item form__element">
                <div class="changes__img changes__img_1"></div>
                <div class="changes__text">{{ "s5p20_text_8" | localize }}</div>
                <div class="changes__sum">
                  {{ bank_data.return.toLocaleString() }}
                  <span class="icon-₪"></span>
                </div>
              </div>
              <div v-if="changes" class="changes__item form__element">
                <div class="changes__img changes__img_2"></div>
                <div class="changes__text">{{ "s5p20_text_9" | localize }}</div>
                <div class="changes__sum">
                  {{ bank_data.return_index.toLocaleString() }}
                  <span class="icon-₪"></span>
                </div>
              </div>
              <div v-if="old_payment" class="changes__item form__element changes__item_red">
                <div class="changes__img changes__img_2"></div>
                <div class="changes__text">{{ "old_program_payment" | localize }}</div>
                <div class="changes__sum">
                  {{ old_payment.toLocaleString() }}
                  <span class="icon-₪"></span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="info info_5">
              {{ "mortgage_offer_no_changes" | localize }}
            </p>
            <p class="info info_6">
              {{ "mortgage_offer_no_changes_text" | localize }}
            </p>
          </div>
        </div>

        <div class="error__container error__container_offer">
          <p class="error" v-if="error">{{ error | localize }}</p>
        </div>

        <div class="result">
          <div class="result__container">
            <div class="result__items">
              <div class="result__item">
                <div class="result__item_text">
                  {{ bank_data.payment.toLocaleString() }}
                </div>
                <div class="result__item_caption">
                  {{ "monthly_return" | localize }}
                </div>
              </div>
              <div class="result__item">
                <div class="result__item_text">
                  {{ mortgage_sum.toLocaleString() }}
                </div>
                <div class="result__item_caption">
                  {{ "bank_money" | localize }}
                </div>
              </div>
              <div class="result__item">
                <div class="result__item_text">
                  {{ bank_data.return.toLocaleString() }}
                </div>
                <div class="result__item_caption">
                  {{ "bank_return" | localize }}
                </div>
              </div>
            </div>

            <button @click="goTender" :class="{ button__form_disabled: error }" class="button button_form">
              {{ "form_tender" | localize }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import VueCookies from 'vue-cookies'
import { commonMixin } from '@/mixins/commonMixin.js'

const refMortgage = VueCookies.get('refMortgage') || {}

export default {
  name: 'Refinance_mortgage_payment',
  components: { Backlink },
  mixins: [commonMixin],
  props: { bank: { type: Number } },
  data: () => ({
    price: refMortgage.price || 0,
    capital: refMortgage.capital || 0,
    mortgage_sum: 0,
    percent: null,
    old_payment: null,
    bank_data: {
      payment: 0,
      return: 0,
      return_index: 0,
      programs: []
    },
    programs: [],
    service_data: [],
    changes: false,
    success: false,
    userServiceId: null,
    userId: null,
    error: null
  }),
  created () {
    this.userId = this.$store.state.user ? this.$store.state.user.id : 0
  },
  mounted () {
    const bank = this.bank || refMortgage.bank || null

    // Выбор банка
    if (!bank) {
      this.$router.push('/refinance-mortgage/bank-offers')
    } else {
      refMortgage.bank = bank
      this.$cookies.set('refMortgage', refMortgage)
    }

    this.$store.commit('loading', true)
    this.axios.get('user_service', {
      params: {
        user_id: this.userId,
        service_id: this.$store.state.services.refMortgage
      }
    }).then((response) => {
      this.mortgage_sum = response.data.amount || 0
      this.userServiceId = response.data.id || 0
      this.axios.get('program/select', {
        params: {
          user_service_id: this.userServiceId,
          is_refinance: 1
        }
      }).then((response) => {
        this.bank_data = response.data.find((b) => b.id === bank)

        // Сбор программ и данных всех предложений банков
        response.data.forEach(bank => {
          this.service_data.push({
            bank_id: bank.id,
            payment: parseInt(bank.payment, 10),
            return: parseInt(bank.return, 10),
            return_index: parseInt(bank.return_index, 10)
          })
          bank.programs.forEach(program => {
            this.programs.push({ ...program, bank_id: bank.id })
          })
        })

        // Банк не выбран
        if (!this.bank_data) {
          this.$router.push('/refinance-mortgage/bank-offers')
        }

        // Расчет платежа по старым программам
        this.axios.get('program/payments/old', { params: { user_service_id: this.userServiceId } }).then((response) => {
          this.old_payment = response.data.payment || null

          if (this.old_payment && parseInt(this.old_payment, 10) <= parseInt(this.bank_data.return, 10)) {
            this.error = 'not_economy_error'
          }
        })

        // Возможны изменения ипотеки: сумма с учетом индекса не равна основной
        this.changes = this.bank_data.return !== this.bank_data.return_index
        this.$store.commit('loading', false)
      }).catch((error) => {
        this.$store.commit('loading', false)
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    }).catch((error) => {
      this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
      this.$store.commit('loading', false)
      this.$scrollTo('.error__container', 300, { offset: -400 })
    })
  },
  methods: {
    goTender () {
      if (this.error) return

      this.$store.commit('loading', true)
      this.axios.put('user_service/' + this.userServiceId, {
        user_id: this.userId,
        service_id: this.$store.state.services.refMortgage,
        status: 6, // Статус: ожидание документов
        bank_id: this.bank_data.id,
        amount: parseInt(this.mortgage_sum, 10),
        payment: parseInt(this.bank_data.payment, 10),
        return: parseInt(this.bank_data.return, 10),
        return_index: parseInt(this.bank_data.return_index, 10)
      }).then(() => {
        // Сохранение расчетных данных услуги
        this.axios.post('/service_data', {
          user: this.userId,
          service: this.$store.state.services.refMortgage,
          data: this.service_data
        }).catch((error) => {
          this.$store.commit('loading', false)
          this.error = error.response.data.error ?? 'server_error'
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
        // Сохранение программ
        this.axios.post('/service_program', {
          user: this.userId,
          service: this.$store.state.services.refMortgage,
          data: this.programs
        }).then(() => {
          this.$store.commit('loading', false)
          this.$router.push({ name: 'documents', params: { service: 'refMortgage'} })
        }).catch((error) => {
          this.$store.commit('loading', false)
          this.error = error.response.data.error ?? 'server_error'
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
      }).catch((error) => {
        this.$store.commit('loading', false)
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    }
  }
}
</script>
